import React from "react";
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import Layout from "../../layouts/layout";

const contactReturn = ({ data: {pageData, hero} }) => {

    hero.subsectionName = 'Suppliers & Subcontractors';

return (
<Layout hero={hero}>
    <div className="container mx-auto mb-16 px-4">
        {pageData.text && 
        <div className="nested-html mx-auto text-justify"
             dangerouslySetInnerHTML={{ __html: pageData.text.childMarkdownRemark.html }}
        />                    
        }
    </div>
</Layout>
)};

export const query = graphql`
query contactThankYouPartner {
      hero: contentfulContact (slug: {eq: "become-a-partner"}) {
        heroBackgroundImage {
          resize(width: 1440) {
           src
          }
        }
        heroHeading
        heroHeadingTwo
      }
    pageData: contentfulContactConfirmationPage(formName: {eq: "become a partner"}) {
         text {
           childMarkdownRemark {
               html
           }
         }
    }
}
`;


export default contactReturn;
